import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

export default function HeadingWithAnimation() {
  const [isVisible, setIsVisible] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // 50% of the target element is visible
  });

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    } else {
      setIsVisible(false); // Reset isVisible state when out of view
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      className={`mx-auto mb-8 text-center ${isVisible ? "slide-in-left" : ""}`}
    >
      <h2 className="font-display text-5xl text-jacarta-700 dark:text-white lg:text-6xl xl:text-7xl text-center uppercase">
        WHAT WE <span className="animate-gradient">DO</span>
      </h2>
      <p className="text-lg dark:text-jacarta-300">
        We connect impact-aligned individuals and organizations globally,
        increasing awareness AND action around problems being faced by people
        and the planet. We leverage immersive technology and ethical AI to
        pioneer innovative ways to work (and play!) together. We make it easy
        engage, inform, educate, inspire, and empower global citizens to address
        pressing environmental and social challenges.
        <br></br>
        <br></br>
      </p>
    </div>
  );
}
