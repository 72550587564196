import buttonText from "@material-tailwind/react/theme/components/button/buttonText";
import Image from "next/image";
import Link from "next/link";
import React from "react";

const ecosystems = [
  {
    id: 1,
    image: "/images/wallets/GAMIFIED.svg",
    title: "IMMERSION, AI, GAMIFIED EXPERIENCES & EDUCATION",
    description:
      "Engage in educational simulations and experiences in the ecosystem",
    icon: "📦",
    bgColor: "bg-accent",
    iconColor: "text-purple-600",
    buttonText: "Learn More",
    link: "/world-of-impact",
  },
  {
    id: 2,
    image: "/images/wallets/CAMPAIGNS.svg",
    title: "COLLABORATIVE IMPACT CAMPAIGNS FOR GOOD",
    description:
      "Participate in co-creation challenges and collaborate to problem-solve",
    icon: "lightbulb",
    bgColor: "bg-accent",
    iconColor: "text-blue-600",
    icon: "⭐",
    buttonText: "Learn More",
    link: "https://impactdots.world/",
  },
  {
    id: 3,
    image: "/images/wallets/IMPACT SHOP.svg",
    title: "IMPACT SHOP - FOR PRODUCTS THAT SAVE THE WORLD",
    description:
      "Explore branded environments demonstrating ESG commitments & impact actions",
    icon: "😊",
    bgColor: "bg-accent",
    iconColor: "text-yellow-600",
    buttonText: "Coming Soon",
    link: "",
  },
];

const OurEcosystemGrid = () => {
  return (
    <div className="container">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 md:gap-[1.875rem]">
        {ecosystems.map((item) => {
          const {
            id,
            image,
            title,
            description,
            icon,
            bgColor,
            iconColor,
            buttonText,
            link,
          } = item;
          return (
            <div
              className="mt-10 mb-10 dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-2.5xl border bg-white p-8 text-center transition-shadow hover:shadow-lg"
              key={id}
            >
              <div className=" rounded-xl text-center flex-1 m-4">
                <div className="flex items-center justify-center">
                  <div
                    className={`rounded-full w-20 h-20 m-4 flex items-center justify-center ${bgColor}`}
                  >
                    <Image
                      width={86}
                      height={86}
                      src={image}
                      className=",-4"
                      alt="wallet"
                    />
                  </div>
                </div>

                <h3 className="mb-2 mt-1 block font-display text-xl font-medium group-hover:text-accent dark:text-white">
                  {title}
                </h3>
              </div>
              <Link
                href={link ? link : "#"}
                target={link ? "_blank" : "_self"}
                className={`inline-block rounded-full py-3 px-8 text-center font-semibold shadow-accent-volume transition-all uppercase ${
                  link
                    ? "bg-accent text-white hover:bg-accent-dark"
                    : "bg-gray-500 text-gray-300 cursor-not-allowed"
                }`}
              >
                {buttonText}
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OurEcosystemGrid;
