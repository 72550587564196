import CollabHub from "../home/collabHub";
import TypingText from "../typewriter/typewriter";
import Link from "next/link";

const WhyImpactoverseHome = () => {
  return (
    <>
      {/* <!-- Process / CTA --> */}
      <section className="py-20 dark:bg-jacarta-800 bg-light-base">
        <div className="container">
          <div className="mx-auto mb-12 text-center">
            <h2 className="font-display text-5xl text-jacarta-700 dark:text-white lg:text-6xl xl:text-7xl text-center uppercase">
              Why <span className="animate-gradient">IMPACTOVERSE</span>
            </h2>
            <p className="text-lg dark:text-jacarta-300 mb-8">
              Immersive, fun interactions & learning that paves the way for
              real-world solutions. An engaging, safe space to showcase
              sustainability activities and innovations. Collaborations, content
              and experiences around topics you care about. Create, contribute
              to or support campaigns that align with your values. Grow
              engagement around impact-aligned products and services. Build an
              impact portfolio that showcases what matters to you. Public or
              dedicated spaces to help achieve your interaction aims.
            </p>
            <Link
              href="/about"
              className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark uppercase mb-12"
            >
              Learn More
            </Link>
            {/* <h2 className="mt-4 font-display text-5xl text-jacarta-700 dark:text-white lg:text-6xl xl:text-7xl text-center uppercase">
              FOR
            </h2>
            <TypingText /> */}
            <CollabHub />
          </div>

          {/* End mx-auto */}

          {/* <CallToActions /> */}
        </div>
      </section>
      {/* <!-- end process / cta -->  */}
    </>
  );
};

export default WhyImpactoverseHome;
