import React from "react";

const HomeCard = ({ title, imageSrc, description }) => {
  return (
    <div className="max-w-md bg-light-base border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:bg-jacarta-700">
      <a href="#">
        <img className="rounded-t-lg" src={imageSrc} alt={title} />
      </a>
      <div className="p-5">
        <a href="#">
          {/* <h3 className="text-lg font-semibold mb-2 dark:text-jacarta-300">{title}</h3> */}
        </a>
        <p className="mb-5 dark:text-jacarta-300 text-left">{description}</p>
      </div>
    </div>
  );
};

export default HomeCard;
