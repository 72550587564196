// import SingleProcessBlock from "../cryto-trading/SingleProcessBlock";

import React from "react";

const WhatWeDo = () => {
  return (
    <>
      {/* <!-- Process / CTA --> */}
      <section className="py-24">
        <div className="container">
          <div className="mx-auto mb-8 text-center">
            <h2 className="font-display text-5xl text-jacarta-700 dark:text-white lg:text-6xl xl:text-7xl text-center uppercase">
              WHAT WE DO
            </h2>
            <h3 className="mb-2 block font-display text-xl font-medium group-hover:text-accent dark:text-white transition transition-colors text-center uppercase">
              WE’RE USING TECH FOR GOOD
            </h3>
            <p className="text-lg dark:text-jacarta-300">
              We’re creating an ecosystem that leverages technology and
              creativity for real-world positive outcomes. We connect
              impact-aligned individuals and organizations globally, increasing
              awareness, engagement AND action around problems being faced by
              people and the planet, offering innovative ways to work (and
              play!) together to solve them.
            </p>
          </div>

          {/* End mx-auto */}

          {/* <CallToActions /> */}
        </div>
        {/* Tab of Copy from BenifitContent */}
      </section>
      {/* <!-- end process / cta -->  */}
    </>
  );
};

export default WhatWeDo;
