import Image from "next/image";
import Link from "next/link";
import Typewriter from "../../components/typewriter/typewriter";
import Logo from "./../../public/images/Ico-LOGO/black.png";
import WhiteLogo from "./../../public/images/Ico-LOGO/white.png";

import TypingText from "../../components/typewriter/typewriter";
import TitleAnimation from "./textAnimation";
import HeroSliderComponent from "./heroSliderComponent";
const HeroPage = () => {
  const heroContent = {
    subTitle:
      "Inform, inspire, connect, and collaborate for meaningful action.",
    title: <>Impact Made </>,
    btnText: " Start Earning",
    heroImg: "/images/hero/hero_crypto_trading.png",
    link: "#",
  };
  return (
    <>
      {/* <!-- Hero --> */}
      <section className="relative py-24">
        <div className="container">
          {/* Start global distribute and dao idea */}
          <div className="lg:flex lg:space-x-12">
            <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
              <Image
                width={1519}
                height={935}
                src="/images/gradient.jpg"
                alt="gradient"
                className="h-full w-full object-cover"
              />
            </picture>
            <picture className="pointer-events-none absolute inset-0 -z-10 hidden dark:block">
              <Image
                width={1519}
                height={935}
                src="/images/gradient_dark.jpg"
                alt="gradient dark"
                className="h-full w-full object-cover"
              />
            </picture>
          </div>
          <div>
            <div className="dark:hidden">
              <Image
                src={WhiteLogo}
                height={42}
                width={195}
                className="mx-auto flex top-0 z-10 bg-blue-500 rounded-md animate-spin-faux3d"
                alt="Impactoverse | Impact Ecosystem"
              />
            </div>
            <div className="hidden dark:block">
              <Image
                src={WhiteLogo}
                height={42}
                width={195}
                className="mx-auto flex top-0 z-10 bg-blue-500 rounded-md animate-spin-faux3d"
                alt="Impactoverse | Impact Ecosystem"
              />
            </div>

            <h1 className="font-display text-5xl text-jacarta-700 dark:text-white lg:text-6xl xl:text-7xl text-center">
              {heroContent.title}
              <br />
              <span className="animate-gradient">Fun & Easy.</span>
            </h1>

            {/* <TypingText /> */}
            {/* <TitleAnimation /> */}

            {/* <h3 className="mb-2 block font-display text-xl font-medium group-hover:text-accent dark:text-white text-center">
              {heroContent.subTitle}
            </h3>
            <h3 className="mb-2 block font-display text-xl font-medium group-hover:text-accent dark:text-white text-center">
              Addressing the world’s biggest environmental and social challenges
            </h3>
            <h3 className="mb-2 block font-display text-xl font-medium group-hover:text-accent dark:text-white text-center">
            Fun, interactive, and immersive technology for real-world positive outcomes
            </h3> */}
          </div>
        </div>
        <HeroSliderComponent />
      </section>

      {/* <!-- end hero -->  */}
    </>
  );
};

export default HeroPage;
