// TickerComponent.jsx

import React from "react";

const ImpactoverseForTextAnimation = () => {
  return (
    <div className="ticker">
      <div className="ticker-logo">
        <div className="ticker-logo-channel">
          <span className="font-display text-4xl text-jacarta-700 dark:text-white lg:text-6xl xl:text-7xl text-center uppercase">
            for
          </span>
        </div>
      </div>
      <div className="ticker-tip">
        <div className="ticker-tip-info">
          {/* <i className="icon-info">i</i>
          <span>The Gas Mask will make you look like a badass.</span> */}
        </div>
      </div>
      <div className="ticker-title">
        {/* <span>Breaking News</span>
        <span>Breaking News</span>
        <span>Breaking News</span>
        <span>Breaking News</span>
        <span>Breaking News</span>
        <span>Breaking News</span>
        <span>Breaking News</span>
        <span>Breaking News</span>
        <span>Breaking News</span>
        <span>Breaking News</span> */}
      </div>
      <div className="ticker-news text-3xl">
        <span>
          <span>Causes</span>
          <span> &#183; </span>
          <span>Brands</span>
           <span> &#183; </span>
          <span>Influencers</span>
           <span> &#183; </span>
          <span>Creators</span>
           <span> &#183; </span>
          <span>Innovators</span>
           <span> &#183; </span>
          <span>Educators</span>
           <span> &#183; </span>
          <span>Supporters</span>
           <span> &#183; </span>
          <span>The Next Generation of Impacteers</span>
           <span> &#183; </span>
          <span>Causes</span>
           <span> &#183; </span>
          <span>Brands</span>
           <span> &#183; </span>
          <span>Influencers</span>
           <span> &#183; </span>
          <span>Creators</span>
           <span> &#183; </span>
          <span>Innovators</span>
           <span> &#183; </span>
          <span>Educators</span>
           <span> &#183; </span>
          <span>Supporters</span>
           <span> &#183; </span>
          <span>The Next Generation of Impacteers</span>
           <span> &#183; </span>
          <span>Causes</span>
           <span> &#183; </span>
          <span>Brands</span>
           <span> &#183; </span>
          <span>Influencers</span>
           <span> &#183; </span>
          <span>Creators</span>
           <span> &#183; </span>
          <span>Innovators</span>
           <span> &#183; </span>
          <span>Educators</span>
           <span> &#183; </span>
          <span>Supporters</span>
           <span> &#183; </span>
          <span>The Next Generation of Impacteers</span>
           <span> &#183; </span>
        </span>
      </div>
    </div>
  );
};

export default ImpactoverseForTextAnimation;
