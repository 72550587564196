import Link from "next/link";
import DaoBlock from "../dao/DaoBlock";
import Image from "next/image";

const homeSection3 = () => {
  return (
    <section className="relative py-24">
      <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
        <Image
          width={1519}
          height={935}
          src="/images/gradient.jpg"
          alt="gradient"
          className="h-full w-full object-cover"
        />
      </picture>
      <picture className="pointer-events-none absolute inset-0 -z-10 hidden dark:block">
        <Image
          width={1519}
          height={935}
          src="/images/gradient_dark.jpg"
          alt="gradient dark"
          className="h-full w-full object-cover"
        />
      </picture>

      <div className="container">
        <div className="mx-auto mb-12 text-center">
          <h1 className="font-display text-5xl text-jacarta-700 dark:text-white lg:text-6xl xl:text-7xl text-center uppercase">
            GET <span className="animate-gradient">INVOLVED</span>
          </h1>
          <p className="text-lg dark:text-jacarta-300">
          Align with impact initiatives in our IMPACT DOT campaigns or step into our WORLD OF IMPACT and motivate and inspire by showcasing your impact. Leverage our platform to keep ahead of the curve with engagement, (affordably!), so you can focus on your core activities and impact. 
      </p>
        </div>

        {/* Start global distribute and dao idea */}
        <div className="lg:flex lg:space-x-12">
          <div className="mb-12 lg:w-1/2">
          <div className="rounded-2.5xl bg-white p-12 dark:bg-jacarta-700">
              <span className="mb-2 mt-1 block font-display text-xl font-medium group-hover:text-accent dark:text-white transition transition-colors">
                KICKSTART A CAMPAIGN
              </span>
              <p className="mb-8 text-lg leading-normal dark:text-jacarta-300">
              Launch an Impact DOT Campaign to earn while you also grow and empower your community of supporters. It’s win-win-win!
              </p>
              <Link
                href="https://impactdots.world/"
                target="_blank" 
                className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
              >
                FIND OUT MORE
              </Link>
            </div>

            {/* <div className="rounded-2.5xl bg-white p-12 dark:bg-jacarta-700">
              <span className="mb-2 mt-1 block font-display text-xl font-medium group-hover:text-accent dark:text-white transition transition-colors">
                WORLD OF IMPACT
              </span>
              <p className="mb-8 text-lg leading-normal dark:text-jacarta-300">
                Dive into a world of amazing experiences, games, innovations and
                opportunities for impact that rewards you!
              </p>
              <a
                href="/world-of-impact"
                target="_blank"
                className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
              >
                Find Out More
              </a>
            </div> */}
          </div>
          <div className="mb-12 lg:w-1/2">
            <div className="h-full rounded-2.5xl bg-white p-12 dark:bg-jacarta-700">
              <span className="mb-2 mt-1 block font-display text-xl font-medium group-hover:text-accent dark:text-white  transition-colors">
              SHOWCASE MY IMPACT 
              </span>
              <p className="mb-8 text-lg leading-normal dark:text-jacarta-300">
              Showcase your impact-aligned campaign, product, service, business, cause, innovation or experience in Impactoverse’s World of Impact.
              </p>
              <Link
                href="/showcase-my-impact"
                className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
              >
                GET IN TOUCH
              </Link>
            </div>
          </div>
        </div>
        {/* End global distribute and dao idea */}
        {/* Start global distribute and dao idea */}
        <div className="lg:flex lg:space-x-12">
         
          <div className="mb-12 lg:w-full">
            
            <div className="h-full rounded-2.5xl bg-white p-12 dark:bg-jacarta-700 border-jacarta-100 rounded-full border">
              <div  className="flex flex-col items-center"> 
              <span className="mb-2 mt-1 block font-display text-xl font-medium group-hover:text-accent dark:text-white ">
                DONE FOR YOU SERVICE
              </span>
              <p className="mb-8 mx-auto max-w-2xl text-center text-lg dark:text-jacarta-300">
              Would you rather have it all done for you? Get in touch, and if it’s the right option for you, we’ll take care of it all!
            </p>
            <Link
           href="/showcase-my-impact"
              className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark uppercase"
            >
              GET IN TOUCH
            </Link>
              </div>
           
              {/* <p className="mb-8 text-lg leading-normal dark:text-jacarta-300">
              Would you rather have it all done for you? Get in touch, and if it’s the right option for you, we’ll take care of it all!

              </p>
              <Link
                href="/showcase-my-impact"
                target="_blank"
                className="flex  rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
              >
                GET IN TOUCH
              </Link> */}
            

           
            </div>
          </div>
        </div>
        {/* End global distribute and dao idea */}

        {/* start call to action weekly digest */}
        {/* <div className="rounded-2.5xl bg-white px-12 py-20 text-center dark:bg-jacarta-700">
          <HeadLine
            text=" Weekly Digest"
            classes="mb-5 font-display text-3xl text-jacarta-700 dark:text-white"
          />

          <p className="mx-auto max-w-2xl text-center text-lg text-jacarta-700 dark:text-white">
            Join our mailing list to stay in the loop with our newest feature
            releases, Impact DOT drops, and tips and tricks for navigating
            Impactoverse
          </p>

          <div className="mx-auto mt-7 max-w-md text-center">
            <form className="relative" onSubmit={(e) => e.preventDefault()}>
              <input
                type="email"
                placeholder="Email address"
                className="dark:bg-jacarta-700 dark:border-jacarta-600 focus:ring-accent border-jacarta-100 w-full rounded-full border py-3 px-4 dark:text-white dark:placeholder-white"
              />
              <button className="hover:bg-accent-dark font-display bg-accent absolute top-2 right-2 rounded-full px-6 py-2 text-sm text-white">
                Subscribe
              </button>
            </form>
          </div>
        </div> */}
        {/* end call to action weekly digest */}
      </div>
    </section>
  );
};

export default homeSection3;
