import Image from "next/image";
import Link from "next/link";
import React from "react";

const FeaturesGrid = ({ features }) => {
  return (
    <div className="container">
      <div className="lg:flex lg:space-x-12">
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <Image
            width={1519}
            height={935}
            src="/images/gradient.jpg"
            alt="gradient"
            className="h-full w-full object-cover"
          />
        </picture>
        <picture className="pointer-events-none absolute inset-0 -z-10 hidden dark:block">
          <Image
            width={1519}
            height={935}
            src="/images/gradient_dark.jpg"
            alt="gradient dark"
            className="h-full w-full object-cover"
          />
        </picture>
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 md:gap-[1.875rem]">
        {features.map((item) => {
          const { id, image, title, description, icon, bgColor, iconColor } =
            item;
          return (
            <div
              key={id}
              className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-2.5xl mb-8 border bg-white p-8 text-center transition-shadow hover:shadow-lg"
            >
              <Image
                width={86}
                height={86}
                src={image}
                className="dark:border-jacarta-600 dark:bg-jacarta-700 border-jacarta-100 mx-auto mb-7 -mt-[3.5rem] h-[5.5rem] w-[5.5rem] rounded-full border bg-white"
                alt="wallet"
              />
              <h3 className="mb-2 mt-1 block font-display text-xl font-medium group-hover:text-accent dark:text-white">
                {title}
              </h3>
              <p className="text-lg dark:text-jacarta-300">{description}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FeaturesGrid;
