const team_data = [
  {
    id: "0Founder & CEO",
    image: "/images/team/team_1.jpg",
    title: "Founder & CEO",
    name: "Noleen Mariappen",
    link: "https://www.linkedin.com/in/noleenm/",
  },
  {
    id: "1Co-Founder & Advisor",
    image: "/images/team/team_2.jpg",
    title: "Editor, TechRenews Newsletter",
    name: "Kala Philo",
    link: "https://www.linkedin.com/in/kalaphilo/",
  },
  {
    id: "2Founder - Optimal Tech",
    image: "/images/team/team_3.jpg",
    title: "Founder - Optimal Tech",
    name: "Dr. Reginald Parker",
    link: "https://www.linkedin.com/in/drparker/",
  },
  {
    id: "3Founder & CEO, Aunua Global",
    image: "/images/team/team_4.jpg",
    title: "Founder & CEO, Aunua Global",
    name: "Karina Murray",
    link: "https://www.linkedin.com/in/karina-murray-169361161/",
  },
  {
    id: "4CEO, Digital Mind State",
    image: "/images/team/team_5.jpg",
    title: "CEO, Digital Mind State",
    name: "Mike Johns",
    link: "https://www.linkedin.com/in/mikejohns/",
  },
  {
    id: "5Fintech Founder & Advisor",
    image: "/images/team/team_6.jpg",
    title: "Fintech Founder & Advisor",
    name: "Dr. Ritesh Jain",
    link: "https://www.linkedin.com/in/drriteshjain/",
  },
  {
    id: "6Deep Tech Influencer",
    image: "/images/team/team_7.jpg",
    title: "Deep Tech Influencer",
    name: "Dr. Ingrid Vasiliu Feltes",
    link: "https://www.linkedin.com/in/ingrid-vasiliu-feltes-mdmba/",
  },
  {
    id: "7EMEA Regional Director, GBA",
    image: "/images/team/team_8.jpg",
    title: "EMEA Regional Director, GBA",
    name: "Priya Guliani",
    link: "https://www.linkedin.com/in/priyaguliani/",
  },
  {
    id: "8Sustainability & ESG Director",
    image: "/images/team/team_9.jpg",
    title: "Sustainability & ESG Director",
    name: "Jeffrey Eades",
    link: "https://www.linkedin.com/in/jeffrey-e-a9074332/",
  },
  {
    id: "8International Publicist",
    image: "/images/team/team_10.jpg",
    title: "International Publicist",
    name: "Kerrin Black",
    link: "https://www.linkedin.com/in/kerrinblack/",
  },
  {
    id: "8International Publicist",
    image: "/images/team/team_11.jpg",
    title: "International Publicist",
    name: "Genevieve Leveille",
    link: "https://www.linkedin.com/in/mgleveille/",
  },
  {
    id: "8Practicing Lawyer",
    image: "/images/team/team_12.jpg",
    title: "Practicing Lawyer",
    name: "Laura Clatworthy",
    link: "https://www.linkedin.com/in/laura-clatworthy-4b589421/",
  },
];
const with_our_partner = [
  {
    id: "0Founder & CEO",
    image: "/images/team/1.jpg",
    title: "Focussed curriculum-aligned resources",
    name: "Noleen Mariappen",
    link: "https://www.linkedin.com/in/noleenm/",
  },
  {
    id: "1Co-Founder & Advisor",
    image: "/images/team/2.jpg",
    title: "Fun learning tied to real-world outcomes",
    name: "Kala Philo",
    link: "https://www.linkedin.com/in/kalaphilo/",
  },
  {
    id: "2Founder - Optimal Tech",
    image: "/images/team/3.jpg",
    title: "Over 700 diverse and engaging lessons",
    name: "Dr. Reginald Parker",
    link: "https://www.linkedin.com/in/drparker/",
  },
  {
    id: "3Founder & CEO, Aunua Global",
    image: "/images/team/4.jpg",
    title: "Interactive videos, quizzes, experiments, plans & kits",
    name: "Karina Murray",
    link: "https://www.linkedin.com/in/karina-murray-169361161/",
  },
  {
    id: "4CEO, Digital Mind State",
    image: "/images/team/5.jpg",
    title: "Benefit from STEM for Sustainability",
    name: "Mike Johns",
    link: "https://www.linkedin.com/in/mikejohns/",
  },
  {
    id: "5Fintech Founder & Advisor",
    image: "/images/team/6.jpg",
    title: "See sustainability in action",
    name: "Dr. Ritesh Jain",
    link: "https://www.linkedin.com/in/drriteshjain/",
  },
  {
    id: "6Deep Tech Influencer",
    image: "/images/team/7.jpg",
    title: "AI resources for planning, content and quizzes",
    name: "Dr. Ingrid Vasiliu Feltes",
    link: "https://www.linkedin.com/in/ingrid-vasiliu-feltes-mdmba/",
  },
  {
    id: "7EMEA Regional Director, GBA",
    image: "/images/team/8.jpg",
    title: "Manage content & classes. Monitor progress",
    name: "Priya Guliani",
    link: "https://www.linkedin.com/in/priyaguliani/",
  },
  {
    id: "8Sustainability & ESG Director",
    image: "/images/team/9.jpg",
    title: "Join a community of impact-aligned educators",
    name: "Jeffrey Eades",
    link: "https://www.linkedin.com/in/jeffrey-e-a9074332/",
  },
  {
    id: "8International Publicist",
    image: "/images/team/10.jpg",
    title: "Gain access to educator CPD resources",
    name: "Kerrin Black",
    link: "https://www.linkedin.com/in/kerrinblack/",
  },
];

export { team_data, with_our_partner };
