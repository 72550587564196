import React from "react";
import Meta from "../../components/Meta";
import { Feature_collections } from "../../components/component";
import NeedHelpBlock from "../../components/crypto/nee-help-block";
import ProcessCta from "../../components/cryto-trading/ProcessCta";
import Benifits from "../../components/dao/Benifits";
import NewseLatter2 from "../../components/dao/newseLatter2";
import Hero_11 from "../../components/hero/hero_11";
import CollabHub from "../../components/home/collabHub";
import WhatWeDo from "../../components/home/WhatWeDo";
import HeroPage from "../../components/homepage_redesign/heroPage";
import ImpactoverseFor from "../../components/homepage_redesign/impactoverseFor";
import HomeSection2 from "../../components/homepage_redesign/homeSection2";
import HomeSection3 from "../../components/homepage_redesign/homeSection3";
import WhyImpactoverseHome from "../../components/homepage_redesign/whyImpactoverseHome";
import YourGatewayToMakeDifference from "../../components/homepage_redesign/yourGatewayToMakeDifference";
import TakeMeThere from "../../components/homepage_redesign/takeMethere";
import FancyBlock from "../../components/crypto/FancyBlock";
import BeniftContent from "../../components/dao/BeniftContent";

const Home_1 = () => {
  return (
    <main>
      <Meta title="Home" />
      <HeroPage />
      <ImpactoverseFor />
      <HomeSection2 />
      <YourGatewayToMakeDifference />

      <WhyImpactoverseHome />
      <Feature_collections />

      <HomeSection3 />

      {/* <TakeMeThere />  */}
      {/* <Hero_11 />
      <WhatWeDo />
      <ProcessCta />
      <Feature_collections />
      <NeedHelpBlock />
      <Benifits />
 */}
      {/* <FancyBlock /> */}
      {/* <NewseLatter2 bgWhite={false} /> */}
    </main>
  );
};

export default Home_1;
